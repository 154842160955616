(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("uplot"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "uplot"], factory);
	else if(typeof exports === 'object')
		exports["UplotVue"] = factory(require("vue"), require("uplot"));
	else
		root["UplotVue"] = factory(root["Vue"], root["uPlot"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_vue__, __WEBPACK_EXTERNAL_MODULE_uplot__) => {
return 